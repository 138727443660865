<!-- 语法 -->
<template>
    <div class="">
        <ssqdan></ssqdan>
       <ssqfu></ssqfu>
      
    </div>
</template>

<script>
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    import ssqfu from '@/components/wanfa/ssqfu.vue'
    import ssqdan from '@/components/wanfa/ssqdan.vue'
    //import api from '@/js/method'
    export default {
        name: '',
        components: {
            ssqfu,
            ssqdan
        },
        data() { // 数据源
            return {
            }
        },
        mounted() {

        },
        computed: {
            // sd() {
            //     return this.$store.state.sd;
            // },
        },
        methods: { // 函数体部分，js的主要逻辑控制               
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
</style>